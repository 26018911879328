import { RefObject, useEffect } from "react"

const useOnClickOutside = (
  ref: RefObject<HTMLDivElement> | undefined,
  triggerRef: RefObject<HTMLDivElement> | undefined,
  handleClick: (event: any) => void
) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (
        ref?.current &&
        !ref?.current?.contains(event?.target) &&
        !triggerRef?.current?.contains(event?.target)
      ) {
        handleClick(event)
      }
    }

    document.addEventListener("mousedown", listener)
    document.addEventListener("touchstart", listener)

    return () => {
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
    }
  }, [ref, handleClick])
}

export default useOnClickOutside
