import React from "react"

export const BetaBadge: React.FC = () => {
  return (
    <span
      className="ant-tag ant-tag-has-color rounded-full ml-2"
      style={{ backgroundColor: "rgb(219, 234, 254)" }}
    >
      <p className="text-black text-sm capitalize">Beta</p>
    </span>
  )
}
