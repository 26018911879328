import axios from "@/modules/shared/AxiosInterceptor"
import { IPaymentMethod } from "@/types/paymentMethod.types"
import { AxiosResponse } from "axios"

type GetPaymentsResponse = {
  paymentMethods: IPaymentMethod[]
}

export async function getPayments(): Promise<AxiosResponse<GetPaymentsResponse>> {
  try {
    const response = await axios.get<GetPaymentsResponse>("/payments")
    return response
  } catch (error) {
    throw error
  }
}

interface ISetupIntentResponse {
  clientSecret: string
  customerId: string
}

export async function postInitialPaymentIntent(
  orgId: string,
  phone: string
): Promise<AxiosResponse<ISetupIntentResponse>> {
  try {
    return axios.post<ISetupIntentResponse>("/payments/payment-intents", {
      orgId,
      phone,
    })
  } catch (error) {
    throw error
  }
}

export async function patchNewDefaultPaymentMethod(
  paymentMethodId: string
): Promise<{ success: boolean }> {
  try {
    return axios.patch(`/payments/${paymentMethodId}`)
  } catch (error) {
    throw error
  }
}

export async function getStripeBillingUrl(): Promise<AxiosResponse<string>> {
  try {
    const res = await axios.post("/billing/portal")
    return res
  } catch (error) {
    throw error
  }
}

export async function createCheckoutSession(
  priceId: string
): Promise<AxiosResponse<{ checkoutUrl: string }>> {
  try {
    const res = await axios.post("/payments/checkout", { priceId })
    return res
  } catch (error) {
    throw error
  }
}
