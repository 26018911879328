import React from "react"
import { Alert } from "antd"
import { useRecoilValue } from "recoil"
import { useTranslation } from "react-i18next"

import { isAuthAtom, isPayingUserSelector, userAtom } from "@/atoms/userAtom"

import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import {
  DEVELOPERS_PAGE_PATH,
  ECOMMERCE_VALIDATE_PATH,
  INVITATION_PAGE_PATH,
} from "@/constants/routes"
import { useFlags } from "flagsmith/react"
import { DateTime } from "luxon"
import KosmoButton from "@/UI/Buttons/KosmoButton"

const MigrationKeysBanner: React.FC<{}> = () => {
  const { t } = useTranslation()
  const { sandboxRouter } = useSandboxRouter()
  const flags = useFlags(["migration_3pl_banner"])
  const { org } = useRecoilValue(userAtom)
  const isAuth = useRecoilValue(isAuthAtom)
  const isPayingUser = useRecoilValue(isPayingUserSelector)
  const orgsToMigrate = JSON.parse(flags?.migration_3pl_banner?.value?.toString() || "{}")?.orgs
  const isToMigrate = orgsToMigrate?.includes(org?.id) && flags?.migration_3pl_banner?.enabled
  const excludedRoutes = [INVITATION_PAGE_PATH, DEVELOPERS_PAGE_PATH, ECOMMERCE_VALIDATE_PATH]

  if (
    !isPayingUser ||
    !isAuth ||
    !isToMigrate ||
    excludedRoutes.includes(sandboxRouter.pathname) ||
    DateTime.now() > DateTime.fromFormat("2024-05-10", "yyyy-MM-dd")
  ) {
    return <></>
  }

  return (
    <Alert
      message={
        <div className="flex justify-center items-center space-x-2 text-[#00000] font-semibold">
          <p>{t("common.migrateToOwnKeyAlert")}</p>
          <a
            href="https://intercom.help/kosmo/en/collections/3698013-getting-your-own-credentials-by-provider"
            target="_blank"
            rel="noopener noreferrer"
          >
            <KosmoButton type="primary">
              <p style={{ fontSize: 12 }}>{t("common.configure")}</p>
            </KosmoButton>
          </a>
        </div>
      }
      type="warning"
      style={{ display: "sticky" }}
      showIcon={false}
      banner
      closable
    />
  )
}

export default MigrationKeysBanner
