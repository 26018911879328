import React from "react"
import { Alert } from "antd"
import { useRecoilValue } from "recoil"
import { useTranslation } from "react-i18next"

import { isAuthAtom, isPayingUserSelector, isSuspendingAtom } from "@/atoms/userAtom"

import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import KosmoButton from "@/UI/Buttons/KosmoButton"
import { INVITATION_PAGE_PATH, ONBOARDING_PAGE_PATH, PROFILE_PAGE_PATH } from "@/constants/routes"

const FreeUserBanner: React.FC<{}> = () => {
  const { t } = useTranslation()
  const { sandboxRouter } = useSandboxRouter()
  const isPayingUser = useRecoilValue(isPayingUserSelector)
  const isSuspending = useRecoilValue(isSuspendingAtom)
  const isAuth = useRecoilValue(isAuthAtom)

  if (
    !isAuth ||
    isSuspending ||
    isPayingUser ||
    [ONBOARDING_PAGE_PATH, INVITATION_PAGE_PATH].includes(sandboxRouter.pathname)
  ) {
    return <></>
  }

  return (
    <div id="add-payment-banner">
      <Alert
        className="bg-kosmoLightBlue"
        message={
          <div className="flex justify-center gap-x-4 text-slate-800 font-semibold items-center">
            <p>{t("onboarding.freePlanBannerDescription")}</p>
            <KosmoButton
              type="primary"
              onClick={() => sandboxRouter.push(`${PROFILE_PAGE_PATH}/?p=plans`)}
            >
              <p style={{ fontSize: 12 }}>{t("profile.planCard.startNow")}</p>
            </KosmoButton>
          </div>
        }
        type="info"
        style={{ display: "sticky", height: 50 }}
        showIcon={false}
        banner
      />
    </div>
  )
}

export default FreeUserBanner
