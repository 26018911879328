import React, { useState } from "react"
import { Layout } from "antd"
import useMediaQuery from "@/utils/useMediaQuery"
import MobileNavSider from "@/layout/Nav/MobileNavSider"
import Header from "@/layout/Header"
import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import { routes } from "@/modules/HOC/WithAuth/routes"
import { ACTIVE_ORDERS_PAGE_PATH, ONBOARDING_PAGE_PATH } from "@/constants/routes"
import { useSetRecoilState } from "recoil"
import { createOrderModalVisibleAtom } from "@/atoms/createOrderModalAtom"
import dynamic from "next/dynamic"
import { OrderType } from "@/types/orders.types"

const CreateOrderModal = dynamic(
  () => import("../modules/shared/CreateOrderModal/CreateOrderModal.container")
)

const Main = (props: { children: React.ReactElement; meta: any; hideMenu?: boolean }) => {
  const { Content } = Layout
  const { sandboxRouter } = useSandboxRouter()
  const [collapsed, setCollapsed] = useState(true)
  let isDesktop = useMediaQuery("(min-width: 1024px)")
  const setCreateOrderModalVisible = useSetRecoilState(createOrderModalVisibleAtom)
  const onCollapse = () => {
    setCollapsed(!collapsed)
  }

  const route = routes[sandboxRouter.pathname]

  return (
    <>
      {props.meta}
      <Layout className="main-layout">
        {!route?.isPublic && !sandboxRouter?.pathname?.includes(ONBOARDING_PAGE_PATH) && (
          <CreateOrderModal
            onAfterCreate={(type: OrderType) => {
              setCreateOrderModalVisible({ visible: false, reOrder: null })
              if (type === OrderType.ACTIVE) {
                sandboxRouter.pushWithContext(
                  { targetHref: ACTIVE_ORDERS_PAGE_PATH, query: { new: true } },
                  true
                )
              }
            }}
          />
        )}
        {!isDesktop && <MobileNavSider collapsed={collapsed} onCollapse={onCollapse} />}
        <Layout className="site-layout h-full">
          <Header collapsed={collapsed} onCollapse={onCollapse} hideMenu={props.hideMenu} />
          {/* Check whether it's the best place to have before merging */}
          <Content className="site-layout-background overflow-hidden">
            <div className="bg-white justify-end h-full">{props.children}</div>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}

export default Main
