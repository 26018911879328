import React from "react"

import { Form, Select } from "antd"
import { BusinessNameFormItem, FloatInput, SemiBoldLabelText, PhoneFormItem } from "@/UI"
import { getAllCountries } from "countries-and-timezones"
import { countries } from "country-data"
import { useTranslation } from "react-i18next"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

interface OrgBusinessFormProps {
  countryCode: string
  handlePhoneSelect: (value: string) => void
}

const { Option } = Select

const OrgBusinessForm: React.FC<OrgBusinessFormProps> = ({ handlePhoneSelect, countryCode }) => {
  const { t } = useTranslation()
  const availableCountries = Object.entries(getAllCountries())
    .filter(([, country]) => {
      const isAfricaCountry =
        country.timezones.every((timezone) => timezone.includes("Africa")) &&
        country.name !== "South Africa"

      return !isAfricaCountry || !["Russia", "Belarus", "Antartica"].includes(country.name)
    })
    .map(([countryCode]) => countryCode)

  return (
    <>
      <div>
        <SemiBoldLabelText>{t("common.forms.businessName")}</SemiBoldLabelText>
        <div className="flex flex-col gap-y-2">
          <BusinessNameFormItem
            name={"orgName"}
            required={true}
            size="large"
            placeholder={t("common.forms.businessName")}
            validationErrorText={t("common.forms.enterName")}
          />
          <Form.Item
            id="email"
            name={"email"}
            validateTrigger="onChange"
            className="mb-4"
            rules={[
              { type: "email", message: t("common.forms.errorEmailField") },
              { required: true, message: t("common.forms.errorEmailField") },
            ]}
          >
            <FloatInput
              name="email"
              size="large"
              prefixIcon={faEnvelope}
              placeholder={t("onboarding.CreateOrgForm.yourProfessionalEmailAddress")}
            />
          </Form.Item>
          <div id="phoneInputContainer">
            <PhoneFormItem
              handlePhoneSelect={handlePhoneSelect}
              countryCode={countryCode || "SG"}
              validateTrigger="onChange"
              size="large"
            />
          </div>
        </div>
      </div>
      <div>
        <SemiBoldLabelText>{t("onboarding.CreateOrgForm.countryOfOperations")}</SemiBoldLabelText>
        <div id="countryInputContainer">
          <Form.Item
            name="country"
            rules={[{ required: true, message: t("common.forms.errorCountryField") }]}
          >
            <Select
              showSearch={true}
              optionFilterProp="label"
              className="flex w-full custom-error"
              size="large"
              placeholder={t("common.forms.chooseYourCountry")}
              // @ts-ignore
              autoComplete="dontshow"
            >
              {availableCountries.map((country, index) => (
                <Option value={country} key={index} label={countries[country]?.name}>
                  <div className="flex space-x-3 items-center">
                    <img
                      src={`https://cdn.kcak11.com/CountryFlags/countries/${country.toLowerCase()}.svg`}
                      width={24}
                      height={24}
                    />
                    <p className="text-md font-semi">{countries[country.toUpperCase()]?.name}</p>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>
    </>
  )
}

export default OrgBusinessForm
