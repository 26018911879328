export interface IError {
  message: string
  key: ErrorKeys
  code: number
  status: number
}

export interface IDisplayableError {
  message: string
  description: string
  translationKey?: string
  skip?: boolean
}

// TODO: As soon as this becomes too big we can split it by domain
export enum ErrorKeys {
  DUPLICATE_ORG = "duplicate_org",
  FORBIDDEN_ACCESS = "forbidden_access",
  ACCESS_TOKEN_NOT_FOUND = "access_token_not_found",
  INVALID_ACCESS_TOKEN = "invalid_access_token",
  CONFIRM_PAYMENT_FAILED = "confirm_payment_failed",
  INTERNAL_SERVER_ERROR = "internal_server_error",
  INVITATION_TEAM_FAILED = "invitation_link_failed",
  DELETE_USER_FAILED = "failed_remove_user_org",
  INVALID_PHONE_NUMBER = "invalid_phone_number",
  PARTNER_API_CALL_FAILED = "error_partner_call",
  INVALID_WEBHOOK_URL = "invalid_webhook_url",
  DELIVERY_INVALID_STATE = "delivery_invalid_state",
  BAD_REQUEST = "bad_request",
  NO_ACTIVE_SUBSCRIPTION = "no_active_subscription",
  FAILED_VALIDATE_CREDENTIALS = "failed_validate_credentials",
  TIPS_CANNOT_BE_MORE_THAN = "tips_cannot_be_more_than",
  INVALID_DELIVERY_TIPS_STATUS = "invalid_delivery_tips_status",
  ROUTE_OPTIMIZATION_QUOTA = "route_optimization_quota",
  DRIVER_HAS_UNCOMPLETED_ORDERS = "driver_has_uncompleted_orders",
  ALREADY_EXISTS = "already_exists",
  ROUTE_DISCONNECTED = "route_disconnected",
  NO_STARTING_LOCATION_FOR_ROUTES = "no_starting_location_for_routes",
  ROUTE_OPTIMIZATION_FAILED = "route_optimization_failed",
}

export class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${JSON.stringify(val)}`)
  }
}
