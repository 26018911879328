import { useRouter } from "next/router"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Dropdown, Space } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLanguage } from "@fortawesome/free-solid-svg-icons"
import { Settings } from "luxon"
import React from "react"

interface LanguageSwitcherProps {
  bordered?: boolean
  trigger?: ("click" | "hover")[]
  withLabel?: boolean
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  trigger,
  withLabel = false,
  bordered = false,
}) => {
  const { t, i18n } = useTranslation()
  const { language: currentLanguageI18n } = i18n
  const router = useRouter()

  const currentLanguage = currentLanguageI18n ?? "en"
  const locales = router.locales ?? [currentLanguage]
  const getNativeLanguageName = (locale: string) => {
    const intl = new Intl.DisplayNames([locale], {
      type: "language",
    })
    return capitalize(intl.of(locale) || locale)
  }
  // create a map of language code to native language name
  const nativeLanguageNames = Object.fromEntries(
    locales.map((locale, _) => [locale, getNativeLanguageName(locale)])
  )

  const [language, setLanguage] = useState({
    value: i18n.language,
    label: capitalize(nativeLanguageNames[currentLanguage] ?? currentLanguage),
  })

  const switchToLocale = useCallback(
    (locale: string) => {
      window.localStorage?.setItem("language_preference", locale)
      i18n.changeLanguage(locale)
    },
    [router]
  )

  const languageSelected = useCallback(
    async ({ key }: { key: string }) => {
      const locale = key
      switchToLocale(locale)
      Settings.defaultLocale = locale || "en"
      setLanguage({
        value: locale,
        label: nativeLanguageNames[locale] ?? locale,
      })
    },
    [switchToLocale]
  )

  return (
    <>
      {withLabel && <span>{t("common.language")}</span>}
      <Dropdown
        trigger={trigger}
        menu={{
          items: locales.map((locale) => {
            const label = capitalize(nativeLanguageNames[locale] ?? locale)
            return {
              key: locale,
              label,
            }
          }),
          onClick: languageSelected,
        }}
      >
        <Button
          {...(bordered ? {} : { type: "text" })}
          size="large"
          onClick={(e) => e.preventDefault()}
        >
          <Space>
            {language.label}
            <FontAwesomeIcon icon={faLanguage} />
          </Space>
        </Button>
      </Dropdown>
    </>
  )
}

function capitalize(lang: string) {
  return lang.slice(0, 1).toUpperCase() + lang.slice(1)
}

export default LanguageSwitcher
