import React from "react"
import { ROOT_PAGE_PATH } from "@/constants/routes"
import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import { Branding } from "@/types/organizations.types"

interface PlatformLogoProps {
  hasOrg?: boolean
  onClick?: () => void
  branding?: Branding
  isMobile?: boolean
}

const PlatformLogo: React.FC<PlatformLogoProps> = ({
  onClick,
  branding,
  hasOrg,
  isMobile = false,
}) => {
  const { sandboxRouter } = useSandboxRouter()
  if (isMobile) {
    return (
      <div className="flex flex-row items-center">
        <div className="flex justify-center flex-shrink-0 ml-4 w-auto cursor-pointer">
          <img
            onClick={() => sandboxRouter.pushWithContext({ targetHref: ROOT_PAGE_PATH })}
            src={
              branding?.platformEnabled
                ? branding?.companyLogoUrl || "/assets/images/logo.svg"
                : "/assets/images/logo.svg"
            }
            alt="logo"
            width={branding?.platformEnabled ? branding?.width || 36 : 36}
            height={branding?.platformEnabled ? branding?.height || 36 : 36}
          />
        </div>
        {branding?.platformEnabled && branding?.companyName && branding?.companyLogoUrl ? (
          <h2
            onClick={() => sandboxRouter.pushWithContext({ targetHref: ROOT_PAGE_PATH })}
            className="float-left ml-3 mt-1 text-left text-black font-semibold text-lg cursor-pointer"
          >
            {branding.companyName}
          </h2>
        ) : (
          <>
            {!branding?.platformEnabled && (
              <h2
                onClick={() => sandboxRouter.pushWithContext({ targetHref: ROOT_PAGE_PATH })}
                className="float-left text-left text-black font-semibold text-lg cursor-pointer"
              >
                Kosmo
              </h2>
            )}
          </>
        )}
      </div>
    )
  }

  return (
    <div
      className={`flex flex-shrink-0 ${hasOrg ? "cursor-pointer" : ""} items-center h-full`}
      onClick={onClick}
    >
      <img
        className={`${hasOrg ? "cursor-pointer" : ""} h-full`}
        src={
          branding?.platformEnabled
            ? branding?.companyLogoUrl || "/assets/images/logo.svg"
            : "/assets/images/logo.svg"
        }
        alt="logo"
        width={branding?.platformEnabled ? branding?.width || 42 : 42}
        height={branding?.platformEnabled ? branding?.height || 42 : 42}
      />
      {branding?.platformEnabled && branding?.companyName && branding?.companyLogoUrl ? (
        <h2 className="float-left text-left text-black font-semibold text-lg ml-1">
          {branding.companyName}
        </h2>
      ) : (
        <>
          {!branding?.platformEnabled && (
            <h2 className="float-left text-left text-black font-semibold text-lg ml-1">Kosmo</h2>
          )}
        </>
      )}
    </div>
  )
}

export default PlatformLogo
