import { getStripeBillingUrl } from "@/api/payments"
import { userAtom } from "@/atoms/userAtom"
import { useRecoilValue } from "recoil"

interface IUseStripeBillingPortal {
  redirectToBillingPortal: () => Promise<void>
}

export const useStripeBillingPortal = (authorized?: boolean): IUseStripeBillingPortal => {
  const user = useRecoilValue(userAtom)

  const redirectToBillingPortal = async () => {
    if (!authorized || !user.org.hasStripeAccount) {
      return
    }
    try {
      const { data: stripeUrl } = await getStripeBillingUrl()
      if (stripeUrl && window) {
        window.open(stripeUrl, "_blank")
      }
    } catch (error) {}
  }

  return {
    redirectToBillingPortal,
  }
}
