import React, { Dispatch, SetStateAction, useEffect } from "react"
import { Card, Form, Input, Select } from "antd"
import useInputPhoneNumber from "@/hooks/useInputPhoneNumber"
import { useTranslation } from "react-i18next"

import { OrgBusinessFormValues } from "@/types/organizations.types"
import { useOrg } from "@/hooks/useOrg"
import { useRecoilValue } from "recoil"
import { userAtom } from "@/atoms/userAtom"
import { formatPhoneNumber } from "@/utils/FormatPhoneNumber"
import OrgBusinessForm from "./components/OrgBusinessForm"
import KosmoButton from "@/UI/Buttons/KosmoButton"
import OrgMarketingForm from "./components/OrgMarketingForm"
import { AnimatePresence, motion } from "framer-motion"
import { AddressAutocomplete, SemiBoldLabelText } from "@/UI"
import { isEmpty } from "lodash"
import { useDeliveryForm } from "@/hooks/useDeliveryForm"
import { CoordinatesLatLng } from "@/types/createSingleOrder.types"
import FormStepperTitle from "@/UI/Layout/FormStepperTitle.component"
import { LocationPin } from "@/UI/SVG/LocationPin"
import { MAX_STOPS_PER_ORDER } from "@/types/orders.types"
import FormItem from "antd/lib/form/FormItem"
import { LabelWithTooltip } from "@/UI/Labels/LabelWithTooltip"
import { DistanceUnit, DistanceUnits } from "@/types/localization.types"
import LanguageSwitcher from "@/modules/shared/Localization/LanguageSwitcher"
import TimezoneSelect from "@/modules/profile/GeneralSettings/components/TimezoneSelector"

const { countries } = require("@/constants/countryCodes.json")

interface CreateOrgFlowProps {
  defaultCountry?: string
  callback?: () => void
  setOrgFirstPickup?: (coordinates: CoordinatesLatLng) => void
  step: number
  setStep: Dispatch<SetStateAction<number>>
  skipAccountStep?: boolean
}

const CreateOrgFlow: React.FC<CreateOrgFlowProps> = ({
  callback,
  defaultCountry,
  setOrgFirstPickup,
  step,
  setStep,
  skipAccountStep,
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const user = useRecoilValue(userAtom)

  const { createNewOrg, isLoading } = useOrg()
  const { countryCode, handlePhoneSelect, phoneCode } = useInputPhoneNumber(form, "phone")

  const selectedCountry = Form.useWatch("country", form)

  const {
    geocodeAutocompletePrediction,
    onLocationSearchChange,
    searchValue,
    isAddressValid,
    formPickupCoordinates,
    city,
  } = useDeliveryForm(form)

  const setPhoneBasedOnCountry = (countryCode: string | undefined) => {
    if (!countryCode) {
      return
    }
    const orgCountry = countries.find((country: any) => country.countryCode === countryCode)
    if (form.getFieldValue("phone")?.length < 6 && orgCountry?.phoneCode) {
      form.setFieldsValue({
        phone: formatPhoneNumber(orgCountry?.phoneCode),
      })
    }
  }

  useEffect(() => {
    if (skipAccountStep && step === 0) {
      setStep((currentStep) => currentStep + 1)
    }
    if (!user) return
    // If we have phone in the form we don't need to set it
    if (!form.getFieldValue("phone")) {
      form.setFieldsValue({
        phone: formatPhoneNumber(phoneCode),
      })
    }

    form.setFieldsValue({
      email: user.email,
      maxBatchSize: 50,
      howLongToWaitAtLocation: 5,
    })
  }, [])

  useEffect(() => {
    if (setOrgFirstPickup && !!formPickupCoordinates.lat) {
      setOrgFirstPickup(formPickupCoordinates)
    }
  }, [formPickupCoordinates])

  useEffect(() => {
    setPhoneBasedOnCountry(selectedCountry)
  }, [selectedCountry])

  useEffect(() => {
    setPhoneBasedOnCountry(defaultCountry)
    if (defaultCountry) {
      form.setFieldValue("country", defaultCountry)
    }
  }, [defaultCountry])

  const handleSelectPrediction = async (prediction: any) => {
    try {
      const geocodeResult = await geocodeAutocompletePrediction(
        prediction,
        form?.getFieldValue("country")
      )
      form.setFieldValue("address", geocodeResult?.address.label)
      form.validateFields()
    } catch (error) {
      console.log(error, "predictions - dropoffDetails")
    }
  }
  const businessFormValues = ["email", "country", "phone", "orgName"]
  const marketingFormValues = ["expectedOrders", "businessType", "other", "expectedEcommerce"]

  const submitOrgCreationFrom = async () => {
    try {
      await form.validateFields(marketingFormValues)
      const businessValues = form.getFieldsValue(businessFormValues) as OrgBusinessFormValues
      const marketingValues = form.getFieldsValue(marketingFormValues)
      if (marketingValues?.expectedEcommerce === "other" && marketingValues?.other) {
        marketingValues.expectedEcommerce = marketingValues.other
      }

      // Store utm values
      const utmData = JSON.parse(window.localStorage?.getItem("utm") || "{}")

      await createNewOrg(
        {
          ...businessValues,
          ...marketingValues,
          coordinates: form.getFieldValue("pickupCoordinates"),
          address: form.getFieldValue("address"),
          city: city,
          ...(!isEmpty(utmData) ? utmData : {}),
          optimizationConfig: {
            maxBatchSize: form.getFieldValue("maxBatchSize"),
            shouldEndAtPickupLocation: form.getFieldValue("shouldEndAtPickupLocation"),
            howLongToWaitAtLocation: form.getFieldValue("howLongToWaitAtLocation"),
          },
          distanceUnit: form.getFieldValue("distanceUnit") || DistanceUnit.KM,
        },
        isEmpty(user?.orgs)
      )

      window.localStorage?.removeItem("utm")

      if (callback) {
        callback()
      }
      form.resetFields()
      setStep(0)
    } catch (error) {
      setStep(0 + (skipAccountStep ? 1 : 0))
      console.log(error)
    }
  }

  const endLocationOptions = [
    {
      label: t("profile.routeSettings.dontUseEndLocation"),
      value: false,
    },
    {
      label: t("profile.routeSettings.useEndLocation"),
      value: true,
    },
  ]

  const steps: Record<
    number,
    {
      component: React.ReactNode
      onClick?: () => void | Promise<void>
      onPrevious?: () => void
      isNextDisabled?: boolean
      title?: string
    }
  > = {
    0: {
      component: (
        <div className="flex flex-col gap-y-4 items-start">
          <div className="flex flex-col">
            <SemiBoldLabelText>{t("common.language")}</SemiBoldLabelText>
            <LanguageSwitcher trigger={["click"]} withLabel={false} bordered />
          </div>
          <div className="flex flex-col">
            <SemiBoldLabelText>{t("onboarding.CreateOrgFlow.preferredTimezone")}</SemiBoldLabelText>
            <TimezoneSelect
              size="large"
              isOnChangeOnMounting
              onChange={(timezone) => {
                if (timezone?.includes("US") || timezone?.includes("Australia")) {
                  form.setFieldValue("distanceUnit", DistanceUnit.MI)
                } else {
                  form.setFieldValue("distanceUnit", DistanceUnit.KM)
                }
              }}
            />
          </div>
          <div className="flex flex-col">
            <SemiBoldLabelText>
              {t("onboarding.CreateOrgFlow.preferredDistanceUnit")}
            </SemiBoldLabelText>
            <FormItem name="distanceUnit" className="mb-0">
              <Select
                style={{ width: 120 }}
                size="large"
                popupMatchSelectWidth={false}
                options={Object.keys(DistanceUnits).map((key) => {
                  return {
                    key: DistanceUnits[key]!.value,
                    label: t(DistanceUnits[key]!.nameKey),
                    value: DistanceUnits[key]!.value,
                  }
                })}
              />
            </FormItem>
          </div>
        </div>
      ),
      onClick: () => {
        setStep(step + 1)
      },
      title: "onboarding.CreateOrgFlow.ConfigureYourAccount",
    },
    1: {
      component: (
        <OrgBusinessForm countryCode={countryCode} handlePhoneSelect={handlePhoneSelect} />
      ),
      onClick: async () => {
        try {
          await form.validateFields(businessFormValues)
          // Because form gets reset on step change
          // And phone value is being incapsulated in PhoneInput it watches the reset
          // So once we change the step we need to set the phone value again
          const phone = form.getFieldValue("phone")
          form.setFieldValue("phone", phone)
          setStep(step + 1)
        } catch (error) {
          return
        }
      },
      isNextDisabled:
        !form.getFieldValue("orgName") ||
        !form.getFieldValue("country") ||
        form.getFieldValue("phone")?.length < 6,
      title: "onboarding.CreateOrgFlow.createYourOrganization",
      ...(skipAccountStep ? {} : { onPrevious: () => setStep(step - 1) }),
    },
    2: {
      component: (
        <AnimatePresence>
          <motion.div
            exit="exit"
            transition={{
              opacity: { duration: 0.5 },
              duration: 0.3,
            }}
            initial={{
              zIndex: -1000,
              x: 100,
              opacity: 0,
            }}
            animate={{
              zIndex: 1,
              x: 0,
              opacity: 1,
            }}
          >
            <div className="flex flex-col gap-y-4">
              <p className="font-semibold text-gray-500">
                {t("onboarding.CreateOrgFlow.youCanEditLater")}
              </p>
              <div>
                <SemiBoldLabelText className="!mb-0">
                  {t("onboarding.createYourPickupStartLocation")}
                </SemiBoldLabelText>
                <AddressAutocomplete
                  name="address"
                  size="large"
                  hideTopLabel
                  label={t("create-order.forms.addressIncludingStreetNumber")}
                  placeholder={t("create-order.forms.addressIncludingStreetNumber")}
                  fetchPredictionsOptions={{ defaultCountry: form?.getFieldValue("country") || "" }}
                  searchValue={searchValue}
                  onSelected={handleSelectPrediction}
                  onChange={(e: any) => {
                    if (onLocationSearchChange) {
                      onLocationSearchChange(e)
                    }
                  }}
                  isInvalid={!isAddressValid && !!searchValue.length}
                  relative
                />
              </div>
              <div>
                <div className="flex flex-col">
                  <LabelWithTooltip
                    label={t("onboarding.CreateOrgFlow.whereDoYourRoutesEnd")}
                    tooltipContent={t("profile.RouteSettings.endLocationTooltip")}
                    isColored={false}
                  />
                  <FormItem name="shouldEndAtPickupLocation">
                    <Select
                      className="min-w-[280px]"
                      options={endLocationOptions}
                      defaultValue={false}
                      size="large"
                    />
                  </FormItem>
                </div>
                <div className="flex flex-col">
                  <LabelWithTooltip
                    label={t("profile.RouteSettings.maximumStopsPerRoute")}
                    tooltipContent={t("profile.RouteSettings.maximumStopsPerRouteTooltip")}
                    isColored={false}
                  />
                  <FormItem name="maxBatchSize">
                    <Input
                      name="maxBatchSize"
                      className="border-[#d9d9d9] !max-w-[120px]"
                      placeholder={t("common.stops")}
                      max={MAX_STOPS_PER_ORDER}
                      step={1}
                      min={0}
                      suffix={<></>}
                      type="number"
                      size="large"
                    />
                  </FormItem>
                </div>
                <div className="flex flex-col">
                  <LabelWithTooltip
                    label={t("profile.RouteSettings.avgTimeAtStop")}
                    tooltipContent={t("profile.RouteSettings.avgTimeAtStopTooltip")}
                    isColored={false}
                  />
                  <FormItem name="howLongToWaitAtLocation">
                    <Input
                      name="howLongToWaitAtLocation"
                      className="!max-w-[100px]"
                      placeholder={"5"}
                      suffix={t("common.minutesAbbreviation")}
                      style={{ borderRadius: "none !important" }}
                      size="large"
                    />
                  </FormItem>
                </div>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      ),
      onClick: async () => {
        try {
          form.setFieldValue("pickupCoordinates", formPickupCoordinates)
          setStep(step + 1)
        } catch (error) {
          setStep(0 + (skipAccountStep ? 1 : 0))
          console.log(error)
        }
      },
      onPrevious: () => setStep(step - 1),
      isNextDisabled: !isAddressValid,
      title: "onboarding.CreateOrgFlow.defineYourRoutesSettings",
    },
    3: {
      component: (
        <AnimatePresence>
          <motion.div
            exit="exit"
            transition={{
              opacity: { duration: 0.5 },
              duration: 0.3,
            }}
            initial={{
              zIndex: -1000,
              x: 100,
              opacity: 0,
            }}
            animate={{
              zIndex: 1,
              x: 0,
              opacity: 1,
            }}
          >
            <OrgMarketingForm form={form} />
          </motion.div>
        </AnimatePresence>
      ),
      onClick: async () => {
        await submitOrgCreationFrom()
      },
      onPrevious: () => setStep(step - 1),
      title: "onboarding.CreateOrgFlow.aboutYourDeliveries",
    },
  }

  const currentStep = steps[step]

  return (
    <div className="flex flex-col gap-y-10">
      <div className="flex flex-col">
        <FormStepperTitle
          title={
            currentStep?.title
              ? t(currentStep?.title)
              : t("onboarding.CreateOrgFlow.createYourOrganization")
          }
          icon={<LocationPin color={"black"} />}
        />
        <Card
          style={{ border: "none", overflow: "auto" }}
          styles={{ body: { padding: 0 } }}
          className="w-full mt-4"
        >
          <div className="flex flex-col mx-auto sm:w-full justify-start md:justify-center md:text-center lg:text-left lg:justify-start ">
            <Form
              form={form}
              id="create-org-form"
              name={"onboarding-store-form"}
              style={{ textAlign: "left" }}
              layout="vertical"
              className="space-y-4 overflow-x-hidden"
              disabled={isLoading}
            >
              {currentStep!.component}
              <div className="flex w-full">
                <div
                  className={`flex mt-4 w-full ${
                    currentStep?.onPrevious ? "justify-between" : "justify-end"
                  }`}
                >
                  {currentStep?.onPrevious ? (
                    <KosmoButton size="large" type="default" onClick={currentStep?.onPrevious}>
                      {t("common.back")}
                    </KosmoButton>
                  ) : (
                    <></>
                  )}
                  <KosmoButton
                    id="onboarding-org-form-submit"
                    size="large"
                    classname={currentStep?.onPrevious ? "w-2/4" : "w-2/4"}
                    isLoading={isLoading}
                    isDisabled={isLoading || currentStep!.isNextDisabled}
                    onClick={steps[step]?.onClick}
                  >
                    {t(step < Object.keys(steps)?.length - 1 ? "common.continue" : "common.submit")}
                  </KosmoButton>
                </div>
              </div>
            </Form>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default CreateOrgFlow
