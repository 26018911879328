import React from "react"
import {
  faCar,
  faClockRotateLeft,
  faUser,
  faBox,
  faGift,
  faBarcode,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Layout, Menu } from "antd"
import { useTranslation } from "react-i18next"

import { isAuthAtom, isPayingUserSelector, isSupportRoleSelector, userAtom } from "@/atoms/userAtom"
import { useRecoilValue } from "recoil"
import { useUser } from "@/hooks/useUser"
import KosmoButton from "@/UI/Buttons/KosmoButton"
import Image from "next/image"

import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import {
  NEW_ORDERS_PAGE_PATH,
  ANALYTICS_PAGE_PATH,
  ACTIVE_ORDERS_PAGE_PATH,
  PROFILE_PAGE_PATH,
  ROOT_PAGE_PATH,
  AFFILIATE_PAGE_PATH,
  SCAN_PAGE_PATH,
} from "@/constants/routes"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { useFlags } from "flagsmith/react"
import Link from "next/link"
import { BetaBadge } from "@/UI/Badges"

interface IMobileNavSiderProps {
  collapsed: boolean
  onCollapse: () => void
}

const { Sider } = Layout

const MobileNavIcon: React.FC<{ icon: IconDefinition }> = ({ icon }) => (
  <div className="flex items-center justify-center w-8 mr-1">
    <FontAwesomeIcon className="flex-shrink-0 h-5 text-black mr-2" icon={icon} />
  </div>
)

const MobileNavSider: React.FC<IMobileNavSiderProps> = (props) => {
  const { t } = useTranslation()
  const flags = useFlags(["show_affiliate_page", "warehouse_barcode_scan"])
  const { sandboxRouter } = useSandboxRouter()

  const { logout } = useUser()
  const isAuth = useRecoilValue(isAuthAtom)
  const isSupportRole = useRecoilValue(isSupportRoleSelector)
  const isPayingUser = useRecoilValue(isPayingUserSelector)
  const { preference } = useRecoilValue(userAtom)
  const { branding } = preference

  const menuItems = [
    {
      key: NEW_ORDERS_PAGE_PATH,
      id: "draft-order-nav-link",
      title: t("new-orders.planOrders"),
      href: NEW_ORDERS_PAGE_PATH,
      private: true,
      icon: <MobileNavIcon icon={faBox} />,
      //hidden:  !flags.show_bulk_order.enabled || sandbox.enabled || isSupportRole,
    },
    {
      key: ACTIVE_ORDERS_PAGE_PATH,
      title: t("common.activeOrders"),
      href: ACTIVE_ORDERS_PAGE_PATH,
      private: true,
      icon: <MobileNavIcon icon={faCar} />,
    },
    {
      key: ANALYTICS_PAGE_PATH,
      title: t("common.analytics"),
      href: ANALYTICS_PAGE_PATH,
      private: true,
      icon: <MobileNavIcon icon={faClockRotateLeft} />,
      hidden: isSupportRole,
      isBeta: false,
    },
    {
      key: SCAN_PAGE_PATH,
      title: t("common.scan"),
      href: SCAN_PAGE_PATH,
      private: true,
      icon: <MobileNavIcon icon={faBarcode} />,
      hidden: !isPayingUser || !flags.warehouse_barcode_scan.enabled,
    },
    {
      key: PROFILE_PAGE_PATH,
      title: t("common.profile"),
      href: PROFILE_PAGE_PATH,
      private: true,
      icon: <MobileNavIcon icon={faUser} />,
    },
    {
      key: AFFILIATE_PAGE_PATH,
      title: t("affiliate.title"),
      href: AFFILIATE_PAGE_PATH,
      private: true,
      icon: <MobileNavIcon icon={faGift} />,
      hidden: isSupportRole || !isPayingUser || !flags.show_affiliate_page.enabled,
    },
  ]

  const MenuItems = () => {
    return menuItems.map((item) => {
      const href = sandboxRouter.getContextHref(item.href)
      if (!item.private) {
        return (
          <Menu.Item
            icon={item.icon}
            key={item.key}
            title={item.title}
            className={`${item.hidden ? "!hidden" : ""}`}
          >
            <Link href={href} prefetch={true}>
              <span className="font-semibold">{item.title}</span>
            </Link>
          </Menu.Item>
        )
      } else if (item.private && isAuth) {
        return (
          <Menu.Item
            id={item.id}
            icon={item.icon}
            key={item.key}
            title={item.title}
            className={`${item.hidden ? "!hidden" : ""}`}
          >
            <Link href={href} prefetch={true}>
              <div className="flex flex-row items-center">
                <span className="font-semibold">{item.title}</span>
                {!!item.isBeta && <BetaBadge />}
              </div>
            </Link>
          </Menu.Item>
        )
      } else return null
    })
  }

  return (
    <Sider
      id="mobile-nav-sider"
      trigger={null}
      collapsible
      collapsed={props.collapsed}
      onCollapse={() => props.onCollapse()}
      style={{
        backgroundColor: "white",
        display: `${props.collapsed ? "none" : ""}`,
        overflow: "hidden",
      }}
      collapsedWidth={"0"}
      width={"99.9%"}
    >
      <div className="flex ml-4 flex-shrink-0 py-4">
        <div className="h-9 w-auto cursor-pointer">
          <Image
            onClick={() => sandboxRouter.pushWithContext({ targetHref: ROOT_PAGE_PATH })}
            src={
              branding?.platformEnabled
                ? branding?.companyLogoUrl || "/assets/images/logo.svg"
                : "/assets/images/logo.svg"
            }
            alt="logo"
            width={branding?.platformEnabled ? branding?.width || 36 : 36}
            height={branding?.platformEnabled ? branding?.height || 36 : 36}
            priority={true}
          />
        </div>
        {branding?.platformEnabled && branding?.companyName && branding?.companyLogoUrl ? (
          <h2
            onClick={() => sandboxRouter.pushWithContext({ targetHref: ROOT_PAGE_PATH })}
            className="float-left ml-3 mt-1 text-left text-black font-semibold text-lg cursor-pointer"
          >
            {branding.companyName}
          </h2>
        ) : (
          <h2
            onClick={() => sandboxRouter.pushWithContext({ targetHref: ROOT_PAGE_PATH })}
            className="float-left ml-3 mt-1 text-left text-black font-semibold text-lg cursor-pointer"
          >
            Kosmo
          </h2>
        )}
      </div>
      <Menu
        style={{ backgroundColor: "white", borderRight: "none" }}
        defaultSelectedKeys={["/"]}
        selectedKeys={[sandboxRouter.pathname]}
        mode="inline"
      >
        {MenuItems()}
        {isAuth && (
          <div
            style={{
              position: "absolute",
              bottom: "30",
            }}
            key="/signout"
            className="w-full p-3"
          >
            <div className="left-0 right-0">
              <KosmoButton
                id="signout-mobile-button"
                type="primary"
                classname="w-full"
                size="large"
                onClick={logout}
              >
                {t("common.Nav.logout")}
              </KosmoButton>
            </div>
          </div>
        )}
      </Menu>
    </Sider>
  )
}

export default MobileNavSider
