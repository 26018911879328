import React from "react"
import { Modal } from "antd"

interface IModalCard {
  title?: string
  onCancel: () => void
  isModalVisible: boolean
  closable?: boolean
  children: React.ReactNode
  style?: React.CSSProperties
  destroyOnClose?: boolean
}

const ModalCard: React.FC<IModalCard> = ({
  title,
  onCancel,
  isModalVisible,
  closable = true,
  children,
  style = {},
  destroyOnClose = true,
}) => {
  return (
    <Modal
      open={isModalVisible}
      closable={closable}
      centered={true}
      footer={null}
      title={title}
      onCancel={onCancel}
      styles={{ body: { maxHeight: "80vh", overflowY: "scroll" } }}
      destroyOnClose={destroyOnClose}
      style={{ ...style }}
    >
      {children}
    </Modal>
  )
}

export default ModalCard
