import React, { ReactElement, useEffect, useState } from "react"
import { Modal } from "antd"

import { useTranslation } from "react-i18next"
import CreateTeamForm from "./CreateTeamForm"
import { useDrivers } from "@/hooks/useDrivers"
import { EnrichedTeam } from "@/types/teams.types"
import { useZones } from "@/hooks/useZones"
import { isPayingUserSelector } from "@/atoms/userAtom"
import { PROFILE_PAGE_PATH } from "@/constants/routes"
import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import PreventWithModal from "@/UI/PreventWithModal"
import { useRecoilValue } from "recoil"
import { useOrgUsers } from "@/hooks/useOrgUsers"
import { managersSelector } from "@/atoms/teamsAtom"
import { driversSelector } from "@/atoms/driversAtom"

interface CreateTeamModalProps {
  button: ReactElement
  team?: EnrichedTeam
}

const CreateTeamModal: React.FC<CreateTeamModalProps> = ({ button, team }) => {
  const { t } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { sandboxRouter } = useSandboxRouter()
  const isPayingUser = useRecoilValue(isPayingUserSelector)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const { fetchDrivers } = useDrivers()
  const availableManagers = useRecoilValue(managersSelector)
  const availableDrivers = useRecoilValue(driversSelector)
  const { fetchOrgUsers } = useOrgUsers()

  const { zones } = useZones()

  useEffect(() => {
    if (isModalVisible) {
      fetchDrivers()
      fetchOrgUsers()
    }
  }, [])

  if (!isPayingUser) {
    return (
      <PreventWithModal
        enable={!isPayingUser}
        label={t("onboarding.freePlanBannerDescription")}
        cta={{
          label: t("profile.planCard.startNow"),
          onClick: () => sandboxRouter.push(`${PROFILE_PAGE_PATH}/?p=plans`),
        }}
      >
        {button}
      </PreventWithModal>
    )
  }

  return (
    <>
      {React.cloneElement(button, { onClick: showModal })}
      <Modal
        open={isModalVisible}
        footer={null}
        closable={true}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose={true}
        zIndex={1000}
        title={team ? t("profile.TeamsSettings.updateTeam") : t("profile.TeamsSettings.createTeam")}
      >
        <CreateTeamForm
          cancelCallback={() => setIsModalVisible(false)}
          drivers={availableDrivers}
          zones={zones}
          managers={availableManagers}
          team={team}
        />
      </Modal>
    </>
  )
}

export default CreateTeamModal
