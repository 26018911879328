import React, { useRef, useState } from "react"

import { useOrg } from "@/hooks/useOrg"
import { useRecoilValue, useResetRecoilState } from "recoil"
import { isSupportRoleSelector, userAtom } from "@/atoms/userAtom"
import { capitalize } from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faPlus } from "@fortawesome/free-solid-svg-icons"
import { Dropdown } from "antd"
import { countries } from "country-data"
import { useTranslation } from "react-i18next"
import { useUser } from "@/hooks/useUser"
import ModalCard from "@/UI/Modals/ModalCard"
import CreateOrgFlow from "@/modules/onboarding/CreateOrgFlow/CreateOrgFlow"
import useOnClickOutside from "@/hooks/useClickOutside"
import { Loader } from "@/UI"
import { createRoutesAtom } from "@/atoms/createRoutesAtom"

interface OrgSwitcherProps {
  isMobile?: boolean
}

const OrgSwitcher: React.FC<OrgSwitcherProps> = ({ isMobile = false }) => {
  const { t } = useTranslation()

  const resetCreateRoutes = useResetRecoilState(createRoutesAtom)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [step, setStep] = useState<number>(0)
  const { logout } = useUser()
  const { switchOrg, isLoading } = useOrg()
  const { org, orgs } = useRecoilValue(userAtom)
  const containerRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)
  const isSupportRole = useRecoilValue(isSupportRoleSelector)

  useOnClickOutside(containerRef, buttonRef, () => {
    setIsDropdownOpen(false)
  })

  const handleOpenOrgForm = () => {
    setIsModalVisible(true)
    setIsDropdownOpen(false)
  }

  const handleSwitchOrg = (targetOrgId: string) => {
    switchOrg(targetOrgId)
    setIsDropdownOpen(false)
    resetCreateRoutes()
  }

  const sortedOrgs = [...orgs]?.sort((a, b) => {
    if (a.name < b.name) {
      return -1
    }
    return 1
  })

  const orgName = capitalize(org.name || org?.country)

  const timerRef = useRef<any>(null)

  return (
    <div className="flex flex-col">
      <div
        id="org-switcher-container"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="flex space-x-4 items-center rounded-md border py-2 px-3 h-8"
        onMouseLeave={() => {
          timerRef.current = setTimeout(() => setIsDropdownOpen(false), 2000)
        }}
        onMouseEnter={() => {
          if (timerRef.current) {
            clearTimeout(timerRef.current)
          }
        }}
        ref={buttonRef}
      >
        <Dropdown
          trigger={["click"]}
          placement="bottom"
          open={isDropdownOpen}
          dropdownRender={() => (
            <div ref={containerRef}>
              <ul
                className="flex flex-col bg-white rounded-md mt-4 max-h-96 overflow-y-scroll"
                style={{ minWidth: 250 }}
              >
                {sortedOrgs?.map((userOrg, index) => {
                  const countryData = countries[userOrg.country]
                  return (
                    <li
                      id={`switch-org-item`}
                      key={index}
                      onClick={() => handleSwitchOrg(userOrg.id)}
                      className={`flex ${index === 0 ? "rounded-t-md" : ""} 
                    ${
                      userOrg.id === org.id ? "bg-indigo-100" : ""
                    } items-center justify-start pl-3 pr-6 py-2 space-x-3 cursor-pointer hover:bg-indigo-200`}
                    >
                      <div
                        className="flex bg-[#111827] rounded-xl items-center justify-center "
                        style={{ width: 30, height: 30 }}
                      >
                        <p className="text-white">{countryData?.alpha2}</p>
                      </div>
                      <p>{capitalize(userOrg.name || countryData?.name)}</p>
                    </li>
                  )
                })}
                {!isSupportRole && orgs?.length > 0 && (
                  <div>
                    <li
                      id="create-org"
                      key={"create-org"}
                      onClick={handleOpenOrgForm}
                      className={`flex items-center justify-start pl-3 pr-6 py-3 space-x-3 cursor-pointer hover:bg-indigo-200`}
                    >
                      <div
                        className="flex bg-white rounded-xl items-center justify-center "
                        style={{ width: 30, height: 30 }}
                      >
                        <FontAwesomeIcon icon={faPlus} size="xl" />
                      </div>
                      <p>{t("common.OrgSwitcher.createNewOrg")}</p>
                    </li>
                  </div>
                )}
                <div className="flex justify-center mt-2 mb-3">
                  <p
                    id="logout"
                    className="text-sm hover:font-bold cursor-pointer"
                    onClick={() => logout()}
                  >
                    {t("common.Nav.logout")}
                  </p>
                </div>
              </ul>
            </div>
          )}
        >
          <div className="flex items-center h-full space-x-2">
            {isLoading && <Loader fontSize={12} />}
            <p
              id="current-org-name"
              style={isMobile ? { maxWidth: 120 } : {}}
              className={`capitalize text-ellipsis whitespace-nowrap overflow-hidden menu-item-no-underline ${
                orgName ? "!text-black" : "text-gray-500"
              }`}
            >
              {orgName || "No Organization"}
            </p>
            <FontAwesomeIcon size="xs" icon={faChevronDown} color="#6B7280" />
          </div>
        </Dropdown>
      </div>
      <ModalCard
        closable
        isModalVisible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose={true}
      >
        <CreateOrgFlow
          callback={() => setIsModalVisible(false)}
          step={step}
          setStep={setStep}
          skipAccountStep={true}
        />
      </ModalCard>
    </div>
  )
}

export default OrgSwitcher
