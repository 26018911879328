import React, { useState, useEffect } from "react"
import moment from "moment-timezone"
import { Select } from "antd"
import { useTranslation } from "react-i18next"
import { Settings } from "luxon"
import { getTimezone } from "@/hooks/useTimezone"
import { useRecoilValue } from "recoil"
import { userAtom } from "@/atoms/userAtom"
import { SizeType } from "antd/lib/config-provider/SizeContext"

interface TimezoneSelectProps {
  withLabel?: boolean
  size?: SizeType
  onChange?: (timezone: string) => void
  isOnChangeOnMounting?: boolean
}

const TimezoneSelect: React.FC<TimezoneSelectProps> = ({
  withLabel = false,
  size = undefined,
  onChange,
  isOnChangeOnMounting,
}) => {
  const { t } = useTranslation()
  const [timezone, setTimezone] = useState("")
  const [timezones, setTimezones] = useState([] as string[])
  const [autoTimezone, setAutoTimezone] = useState("")
  const { org } = useRecoilValue(userAtom)

  const defaultOrgTimezone = getTimezone(org.country || "SG")

  useEffect(() => {
    // Get all timezones
    setTimezones(moment.tz.names())

    // Detect user's device timezone
    const userTimezone = moment.tz.guess()
    setAutoTimezone(userTimezone)

    // Set initial timezone (could be from user settings or default org timezone)
    // Load timezone from local storage or default
    const savedTimezone =
      localStorage.getItem("userTimezone") || userTimezone || "default" || "auto"
    setTimezone(savedTimezone)
    if (onChange && isOnChangeOnMounting) {
      onChange(savedTimezone)
    }
  }, [defaultOrgTimezone])

  const handleTimezoneChange = (value: string) => {
    setTimezone(value)
    localStorage.setItem("userTimezone", value)
    const tz =
      value === "auto" ? moment.tz.guess() : value === "default" ? defaultOrgTimezone : value
    Settings.defaultZone = tz
    moment.tz.setDefault(tz)
  }

  return (
    <div className="flex flex-col gap-y-1">
      {withLabel && (
        <span>
          <label htmlFor="timezone-select">{t("profile.GeneralSettings.timezone")}</label>
        </span>
      )}
      <Select
        showSearch
        style={{ width: 300 }}
        placeholder={t("profile.GeneralSettings.timezonePlaceholder")}
        optionFilterProp="children"
        value={timezone}
        size={size}
        onChange={(timezone) => {
          handleTimezoneChange(timezone)
          if (onChange) {
            onChange(timezone)
          }
        }}
      >
        <Select.Option key={"default"} value={"default"}>
          {t("profile.GeneralSettings.defaultTimezone")} ({defaultOrgTimezone}){" "}
          {moment.tz(defaultOrgTimezone).format("Z")}
        </Select.Option>
        <Select.Option key={"auto"} value={"auto"}>
          {t("profile.GeneralSettings.automaticTimezone")} ({autoTimezone}){" "}
          {moment.tz(autoTimezone).format("Z")}
        </Select.Option>
        {timezones.map((tz) => (
          <Select.Option key={tz} value={tz}>
            {tz} {moment.tz(tz).format("Z")}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

export default TimezoneSelect
