import axios from "@/modules/shared/AxiosInterceptor"
import { AxiosResponse } from "axios"
import { IUser } from "@/types/user.types"
import {
  ICreateOrgPayload,
  Organization,
  IPreference,
  PatchOrgPayload,
} from "@/types/organizations.types"

export function patchOrgPreferences(preferences: IPreference) {
  const baseUrl = "/orgs/preference"

  return axios
    .post<AxiosResponse<{}>>(baseUrl, preferences)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function postOrg(org: ICreateOrgPayload): Promise<AxiosResponse<Organization>> {
  const baseUrl = "/orgs"

  return axios
    .post(baseUrl, org)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function patchOrg(data: PatchOrgPayload): Promise<AxiosResponse<IUser["org"]>> {
  return axios.patch("/orgs", {
    ...data,
  })
}
