import React, { useContext } from "react"
import { Badge, Layout, Menu } from "antd"
import { useTranslation } from "react-i18next"
import Link from "next/link"
import {
  isAuthAtom,
  isSupportRoleSelector,
  isPayingUserSelector,
  hasOrgAtom,
  userAtom,
} from "@/atoms/userAtom"
import { useRecoilValue } from "recoil"
import { useFlags } from "flagsmith/react"
import EnvIndication from "./EnvIndication"
import SandboxSwitcher, { SandboxContext } from "./SandboxSwitcher"
import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import {
  NEW_ORDERS_PAGE_PATH,
  ANALYTICS_PAGE_PATH,
  ACTIVE_ORDERS_PAGE_PATH,
  MAP_PAGE_PATH,
  ONBOARDING_PAGE_PATH,
  PROFILE_PAGE_PATH,
  INVITATION_PAGE_PATH,
} from "@/constants/routes"
import OrgSwitcher from "@/modules/shared/OrgSwitcher/OrgSwitcher.container"
import { faGift } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SuspendedOrgBanner from "@/modules/shared/SuspendedOrgBanner/SuspendedOrgBanner"
import { BetaBadge } from "@/UI/Badges"
import FreeUserBanner from "@/modules/shared/FreeUserBanner/FreeUserBanner"
import MigrationKeysBanner from "@/modules/shared/MigrationKeysBanner/MigrationKeysBanner"
import PlatformLogo from "../PlatformLogo"
import GlobalCreateOrderButton from "@/modules/shared/GlobalCreateOrderButton/GlobalCreateOrderButton"

interface IDesktopNavProps {}

const { Header } = Layout

const DesktopHeader: React.FC<IDesktopNavProps> = () => {
  const { t } = useTranslation()
  const { sandboxRouter } = useSandboxRouter()

  const { preference } = useRecoilValue(userAtom)

  const { branding } = preference

  const flags = useFlags([
    "show_bulk_order",
    "show_map_page",
    "show_affiliate_page",
    "nash_branding",
  ])

  const isAuth = useRecoilValue(isAuthAtom)
  const hasOrg = useRecoilValue(hasOrgAtom)
  const isPayingUser = useRecoilValue(isPayingUserSelector)
  const sandbox = useContext(SandboxContext)
  const isSupportRole = useRecoilValue(isSupportRoleSelector)

  const menuItems = [
    {
      key: NEW_ORDERS_PAGE_PATH,
      title: t("new-orders.planOrders"),
      href: NEW_ORDERS_PAGE_PATH,
      private: true,
      disabled: false,
      hidden: isSupportRole || !hasOrg,
    },
    {
      key: ACTIVE_ORDERS_PAGE_PATH,
      title: t("common.activeOrders"),
      href: ACTIVE_ORDERS_PAGE_PATH,
      private: true,
      hidden: !hasOrg,
    },
    {
      key: MAP_PAGE_PATH,
      title: t("common.map"),
      href: MAP_PAGE_PATH,
      private: true,
      hidden: sandbox.enabled || !flags.show_map_page.enabled || isSupportRole || !hasOrg,
    },
    {
      key: ANALYTICS_PAGE_PATH,
      title: t("common.analytics"),
      href: ANALYTICS_PAGE_PATH,
      private: true,
      disabled: !isPayingUser,
      hidden: isSupportRole || !hasOrg,
      isBeta: false,
    },
  ]

  const MenuItems = () => {
    return menuItems.map((item, index) => {
      const href = sandboxRouter.getContextHref(item.href)
      if (!item.private) {
        return (
          <Menu.Item
            id={item.key}
            key={item.key}
            title={item.title}
            className={`w-fit ${item.hidden ? "!hidden" : ""}`}
            onClick={() => sandboxRouter.pushWithContext({ targetHref: href })}
          >
            <Link href={href} prefetch={true}>
              <a>
                <div className="font-semibold select-none">{item.title}</div>
              </a>
            </Link>
          </Menu.Item>
        )
      } else if (item.private && isAuth) {
        return (
          <Menu.Item
            id={item.key}
            key={item.key}
            title={item.title}
            className={`w-fit ${item.hidden ? "!hidden" : ""}`}
            disabled={item.disabled}
            onClick={() => sandboxRouter.pushWithContext({ targetHref: href })}
          >
            <Link href={href} prefetch={true}>
              <>
                <div
                  className={`font-semibold select-none ${item.disabled ? "text-gray-600" : ""}`}
                >
                  {item.title}
                </div>
                {!!item.isBeta && <BetaBadge />}
              </>
            </Link>
          </Menu.Item>
        )
      } else return <React.Fragment key={index} />
    })
  }

  return (
    <>
      <SuspendedOrgBanner />
      <FreeUserBanner />
      <MigrationKeysBanner />
      <Header className="site-layout-background flex items-end p-0 bg-white">
        <Menu
          defaultSelectedKeys={["/"]}
          selectedKeys={[sandboxRouter.pathname]}
          mode="horizontal"
          className="desktop-nav w-full flex items-center h-full"
        >
          <Menu.Item
            key="/home"
            className="pr-12 !cursor-default !flex items-center justify-center h-full"
            disabled={true}
          >
            <PlatformLogo
              hasOrg={hasOrg}
              onClick={() => {
                if (!hasOrg) {
                  return
                }
                sandboxRouter.pushWithContext({
                  targetHref: isSupportRole ? ACTIVE_ORDERS_PAGE_PATH : NEW_ORDERS_PAGE_PATH,
                })
              }}
              branding={branding}
            />
          </Menu.Item>
          {MenuItems()}
        </Menu>
        <Menu mode="horizontal" className="desktop-nav w-full flex items-center justify-end">
          {isAuth && (
            <>
              {/* <Menu.Item key="newVersionAvailable" className="ml-auto cursor-default">
                {!newVersionAvailable && (
                  <Menu.Item
                    onClick={() => {
                      localStorage.setItem("version", newVersionAvailable!)
                      window.localStorage?.removeItem(LOCAL_STORAGE_FILTERS_KEY)
                      setUser((userState: IUser) => ({
                        ...userState,
                        newVersionAvailable: undefined,
                      }))
                      setTimeout(() => sandboxRouter.reload(), 0)
                    }}
                  >
                    <FontAwesomeIcon icon={faRefresh} />
                    <span className="font-semibold ml-2">
                      {t("common.newVersionDetected.button")}
                    </span>
                  </Menu.Item>
                )}
              </Menu.Item> */}
              <Menu.Item key="environment" className="ml-auto cursor-default !pr-0" disabled={true}>
                {process.env.NEXT_PUBLIC_REACT_APP_ENVIRONMENT === "development" && (
                  <EnvIndication environment="Staging" />
                )}
              </Menu.Item>
              {sandbox.enabled && (
                <Menu.Item
                  key="sandbox"
                  id="sandbox"
                  className="cursor-pointer !pl-0"
                  disabled={true}
                >
                  <SandboxSwitcher />
                </Menu.Item>
              )}
              <Menu.Item
                className="cursor-pointer !pr-0 menu-item-no-underline"
                key="create-order"
                disabled={true}
              >
                {!isSupportRole &&
                  ![ONBOARDING_PAGE_PATH, INVITATION_PAGE_PATH]?.includes(
                    sandboxRouter?.pathname
                  ) && <GlobalCreateOrderButton />}
              </Menu.Item>
              {hasOrg && (
                <Menu.Item
                  key="settings"
                  title={t("common.settings")}
                  onClick={() => sandboxRouter.pushWithContext({ targetHref: PROFILE_PAGE_PATH })}
                >
                  <Link
                    href={sandboxRouter.getContextHref(PROFILE_PAGE_PATH)}
                    className="select-none"
                  >
                    <a>
                      <span className="font-semibold select-none">{t("common.settings")}</span>
                    </a>
                  </Link>
                </Menu.Item>
              )}
              {!isSupportRole && isPayingUser && flags.show_affiliate_page.enabled && (
                <Menu.Item key="affiliate" title={t("affiliate.title")} className="!pl-0">
                  <Link
                    href={sandboxRouter.getContextHref("/affiliate")}
                    className="font-semibold select-none"
                  >
                    <a>
                      <Badge dot style={{ background: "#4100FF" }}>
                        <FontAwesomeIcon icon={faGift} size="lg" />
                      </Badge>
                    </a>
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item key="org-switcher" className="select-none menu-item-no-underline !pl-0">
                <OrgSwitcher />
              </Menu.Item>
            </>
          )}
        </Menu>
      </Header>
    </>
  )
}

export default DesktopHeader
