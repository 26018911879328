import { ICustomer } from "@/api/customers"
import { IOrder } from "@/types/orders.types"
import { atom } from "recoil"

interface CreateOrderModalAtom {
  visible: boolean,
  reOrder?: IOrder | null
  savedAddresses?: ICustomer[]
  timeAtStop?: number
  arrivalTimes?: {
    start: string
    end: string
  }
}

export const createOrderModalVisibleAtom = atom<CreateOrderModalAtom>({
  key: "createOrderModal",
  default: {
    visible: false,
    reOrder: null
  }
})
