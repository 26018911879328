import React from "react"
import useMediaQuery from "@/utils/useMediaQuery"

import DesktopHeader from "./Nav/DesktopHeader"
import MobileHeader from "./Nav/MobileHeader"

interface IHeaderProps {
  collapsed: boolean
  onCollapse: () => void
  hideMenu?: boolean
}
const Header: React.FC<IHeaderProps> = (props) => {
  let isDesktop = useMediaQuery("(min-width: 860px)")

  if (props.hideMenu) {
    return <></>
  }
  return isDesktop ? (
    <DesktopHeader />
  ) : (
    <MobileHeader
      collapsed={props.collapsed}
      onCollapse={props.onCollapse}
      hideMenu={props.hideMenu}
    />
  )
}

export default Header
