import { atom } from "recoil"
import { GlobalOrdersMetadata } from "@/types/orders.types"
import { Stop } from "@/types/stops.types"

export interface StopsAtom {
  stops: Stop[]
  metadata: GlobalOrdersMetadata
}

export const defaultMetadata = {
  total_successful_deliveries: 0,
  total_successful_deliveries_cost: 0,
  avg_cost_per_successful_deliveries: 0,
  total: 0,
  created: 0,
  to_pickup: 0,
  picked_up: 0,
  delivered: 0,
  cancelled: 0,
  returning: 0,
  returned: 0,
  at_hub: 0,
  to_dropoff: 0,
  cost: 0,
}

export const stopsAtom = atom({
  key: "stops",
  default: {
    stops: [] as Stop[],
    metadata: defaultMetadata
  } as StopsAtom
})
