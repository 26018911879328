import React from "react"

import { Modal } from "antd"
import { useTranslation } from "react-i18next"
import UploadTemplateActionsButton from "./UploadTemplateActionsButton"
import UploadTemplate from "../UploadTemplate.container"
import { DraftOrder } from "@/types/draftOrder.types"
import { IQuotePayload } from "@/types/quotes.types"

const UploadTemplateModal: React.FC<{
  open: boolean
  withPickup?: boolean
  onClose: () => void
  handleUploadCallback: (uploadedOrders: DraftOrder[] | IQuotePayload[]) => Promise<any>
  isPublic?: boolean
}> = ({ open, onClose, handleUploadCallback, withPickup, isPublic }) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={open}
      title={
        <div className="flex justify-between w-[95%]">
          {t("common.importFile")}
          <UploadTemplateActionsButton />
        </div>
      }
      destroyOnClose={true}
      onCancel={() => {
        onClose()
      }}
      footer={null}
    >
      <UploadTemplate
        withPickup={withPickup}
        handleUploadCallback={async (uploadedOrders) => {
          await handleUploadCallback(uploadedOrders)
          onClose()
        }}
        onCancel={onClose}
        isPublic={isPublic}
      />
    </Modal>
  )
}
export default UploadTemplateModal
