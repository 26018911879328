import React from "react"
import { Alert, message } from "antd"
import { useRecoilValue } from "recoil"
import { useTranslation } from "react-i18next"

import { isAuthAtom, isOwnerAtom, isSuspendingAtom, userAtom } from "@/atoms/userAtom"

import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import {
  DEVELOPERS_PAGE_PATH,
  ECOMMERCE_VALIDATE_PATH,
  INVITATION_PAGE_PATH,
} from "@/constants/routes"
import { DateTime } from "luxon"
import { useStripeBillingPortal } from "@/hooks/useStripeBillingPortal"

const SuspendedOrgBanner: React.FC<{}> = () => {
  const { t, i18n } = useTranslation()
  const { sandboxRouter } = useSandboxRouter()
  const { org } = useRecoilValue(userAtom)
  const isSuspending = useRecoilValue(isSuspendingAtom)
  const isAuth = useRecoilValue(isAuthAtom)
  const isOwner = useRecoilValue(isOwnerAtom)
  const { redirectToBillingPortal } = useStripeBillingPortal(isOwner)

  const excludedRoutes = [INVITATION_PAGE_PATH, DEVELOPERS_PAGE_PATH, ECOMMERCE_VALIDATE_PATH]

  if (!isAuth || !isSuspending || excludedRoutes.includes(sandboxRouter.pathname)) {
    return <></>
  }

  const redirectToBilling = async () => {
    if (!isOwner) {
      message.error(t("common.onlyAdminOrOwnerCanChange"))
    }
    await redirectToBillingPortal()
  }

  return (
    <div id="add-payment-banner">
      <Alert
        className="bg-black"
        onClick={redirectToBilling}
        message={
          <div className="flex justify-center space-x-2 text-white">
            {isSuspending && (
              <p>
                {t("common.orgIsSuspendingAt", {
                  suspendedAt: DateTime.fromMillis(org?.suspendedAt || 0)
                    .setLocale(i18n.language)
                    .toRelative({ unit: "hours" }),
                })}
              </p>
            )}
          </div>
        }
        type="info"
        style={{ display: "sticky", cursor: "pointer" }}
        showIcon={false}
        banner
        closable
      />
    </div>
  )
}

export default SuspendedOrgBanner
