import React, { useContext } from "react"
import { faBars, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Layout } from "antd"
import EnvIndication from "./EnvIndication"
import SandboxSwitcher, { SandboxContext } from "./SandboxSwitcher"
import { isAuthAtom, isPayingUserSelector, isSupportRoleSelector, userAtom } from "@/atoms/userAtom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { ONBOARDING_PAGE_PATH } from "@/constants/routes"
import OrgSwitcher from "@/modules/shared/OrgSwitcher/OrgSwitcher.container"
import { createOrderModalVisibleAtom } from "@/atoms/createOrderModalAtom"
import SuspendedOrgBanner from "@/modules/shared/SuspendedOrgBanner/SuspendedOrgBanner"
import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import PlatformLogo from "../PlatformLogo"

interface IMobileHeaderProps {
  collapsed: boolean
  onCollapse: () => void
  hideMenu?: boolean
}
const MobileHeader: React.FC<IMobileHeaderProps> = (props) => {
  const { preference } = useRecoilValue(userAtom)
  const { branding } = preference

  const { Header } = Layout
  const { sandboxRouter } = useSandboxRouter()
  const isAuth = useRecoilValue(isAuthAtom)
  const sandbox = useContext(SandboxContext)
  const setCreateOrderModalVisible = useSetRecoilState(createOrderModalVisibleAtom)
  const isSupportRole = useRecoilValue(isSupportRoleSelector)
  const isPayingUser = useRecoilValue(isPayingUserSelector)
  return (
    <>
      <SuspendedOrgBanner />
      <Header className="flex site-layout-background p-0 bg-white items-center">
        {props.collapsed ? (
          <div className="flex w-full pr-5 items-center justify-between">
            <PlatformLogo branding={branding} isMobile />
            <div className="ml-auto">
              {process.env.NEXT_PUBLIC_REACT_APP_ENVIRONMENT === "development" && (
                <div className="mr-2">
                  <EnvIndication environment="Staging" />
                </div>
              )}
            </div>
            <div className="flex items-center space-x-2">
              {isAuth &&
                isPayingUser &&
                !isSupportRole &&
                !sandboxRouter?.pathname?.includes(ONBOARDING_PAGE_PATH) && (
                  <Button
                    type="primary"
                    shape="round"
                    onClick={() => setCreateOrderModalVisible({ visible: true })}
                  >
                    <div className="flex gap-x-2 items-center">
                      <FontAwesomeIcon icon={faPlus} color="#FFF" />
                    </div>
                  </Button>
                )}
              {isAuth && sandbox.enabled && (
                <div className="mr-3">
                  <SandboxSwitcher />
                </div>
              )}
              {isAuth && <OrgSwitcher isMobile />}
              {!props.hideMenu && isAuth && (
                <FontAwesomeIcon
                  id="expand-nav-menu-button"
                  className="flex flex-shrink-0 h-5 text-black lg:hidden cursor-pointer"
                  fontSize={12}
                  icon={faBars}
                  onClick={() => props.onCollapse()}
                />
              )}
            </div>
          </div>
        ) : (
          <a>
            <FontAwesomeIcon
              id="collapse-nav-menu-button"
              className="flex flex-shrink-0 h-5 text-black absolute top-6 right-5 lg:hidden"
              icon={faMinus}
              fontSize={12}
              onClick={() => props.onCollapse()}
            />
          </a>
        )}
      </Header>
    </>
  )
}

export default MobileHeader
