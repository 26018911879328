import React, { useState } from "react"

import { Form, FormInstance, Select } from "antd"
import { FloatTextArea, ProductTypeFormItem, SemiBoldLabelText } from "@/UI"
import { useTranslation } from "react-i18next"
import { IProductType, ProductTypes } from "@/types/productTypes.types"

interface OrgMarketingFormProps {
  form: FormInstance
}

const OrgMarketingForm: React.FC<OrgMarketingFormProps> = ({ form }) => {
  const { t } = useTranslation()
  const [isOtherEcommerce, setIsOtherCommerce] = useState<boolean>(false)

  const handleProductTypeChange = (selectedProductType: IProductType) => {
    const productType = Object.values(ProductTypes).find((pt) => pt === selectedProductType)
    form.setFieldsValue({
      productType: productType,
    })
    form.validateFields(["businessType"])
  }

  const expectedOrdersOptions = [
    { label: "Less than 1,000", value: "Less than 1,000" },
    { label: "1,000 to 10,000", value: "1,000 to 10,000" },
    { label: "10,000 to 30,000", value: "10,000 to 30,000" },
    { label: "More than 30,000", value: "More than 30,000" },
  ]

  const expectedEcommerceOptions = [
    { label: "Shopify", value: "Shopify" },
    { label: "Lazada", value: "Lazada" },
    { label: "Shopee", value: "Shopeee" },
    { label: "Wix", value: "Wix" },
    { label: "WooCommerce", value: "WooCommerce" },
    { label: "BigCommerce", value: "BigCommerce" },
    { label: "Magento", value: "Magento" },
    { label: "Other", value: "Other" },
    { label: "None", value: "None" },
  ]

  return (
    <div className="space-y-4">
      <div>
        <SemiBoldLabelText>{t("onboarding.CreateOrgForm.additionalInformation")}</SemiBoldLabelText>
        <div className="space-y-4">
          <ProductTypeFormItem
            handleProductTypeChange={handleProductTypeChange}
            validationErrorText={t("create-order.forms.selectProductTypeValidation")}
            name={"businessType"}
            label={t("common.whatBestDescribesYou")}
            placeholder={t("onboarding.CreateOrgForm.chooseBusinessType")}
            required={false}
            size="large"
          />
          <Form.Item name="expectedOrders" label={t("common.expectedMonthlyVolume")}>
            <Select
              className="custom-error"
              placeholder={t("onboarding.CreateOrgForm.selectExpectedDailyOrder")}
              options={expectedOrdersOptions}
              size="large"
            />
          </Form.Item>
        </div>
      </div>
      <div>
        <Form.Item
          name="expectedEcommerce"
          label={t("onboarding.CreateOrgForm.platformImSellingOn")}
        >
          <Select
            mode="multiple"
            className="custom-error"
            placeholder={t("onboarding.CreateOrgForm.selectEcommerceOrPosPlatform")}
            onChange={(values) => setIsOtherCommerce(values?.includes("Other"))}
            allowClear
            options={expectedEcommerceOptions}
            size="large"
          />
        </Form.Item>
        {isOtherEcommerce && (
          <Form.Item name="other">
            <FloatTextArea
              label="Other Ecommerce platform"
              size="large"
              placeholder={t("onboarding.CreateOrgForm.enterOtherEcommerceOrPosPlatform")}
            />
          </Form.Item>
        )}
      </div>
    </div>
  )
}

export default OrgMarketingForm
