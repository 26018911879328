import React, { useEffect, useState } from "react"
import { Checkbox, Divider, Form, Input, Select, Space, Switch, Tabs, message } from "antd"

import { useTranslation } from "react-i18next"
import { ITeamMember } from "@/types/user.types"
import { capitalize } from "lodash"
import KosmoButton from "@/UI/Buttons/KosmoButton"
import DriverModal from "../../DriversSettings/components/DriverModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons"
import { useTeams } from "@/hooks/useTeams"
import { CreateTeamPayload, EnrichedTeam } from "@/types/teams.types"
import { Driver } from "@/types/drivers.types"
import { Zone } from "@/types/zones.types"
import { useFlags } from "flagsmith/react"
import { LabelWithTooltip } from "@/UI/Labels/LabelWithTooltip"
import TabPane from "antd/lib/tabs/TabPane"

interface CreateTeamFormProps {
  cancelCallback?: () => void
  managers: ITeamMember[]
  drivers: Driver[]
  zones: Zone[]
  team?: EnrichedTeam
}

const CreateTeamForm: React.FC<CreateTeamFormProps> = ({
  cancelCallback,
  managers,
  drivers,
  zones,
  team,
}) => {
  const { t } = useTranslation()
  const flags = useFlags(["nash_edg_demo"])
  // ENG Demo
  const [driverRequirementsEnabled, setDriverRequirementsEnabled] = useState<boolean>(false)
  const [requireRecipientId, setRequireRecipientId] = useState<boolean>(false)
  const [nominatedItems, setNominatedItems] = useState<boolean>(false)
  const [form] = Form.useForm()

  const { createTeam, updateTeam, isLoading } = useTeams()

  useEffect(() => {
    if (team) {
      form.setFieldsValue({
        name: team?.name,
        managers: team?.managers?.map((manager) => manager?.id),
        drivers: team?.drivers?.map((driver) => driver?.id),
        zones: team?.zones?.map((zone) => zone?.id),
      })
    }
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      drivers: team?.drivers?.map((driver) => driver?.id),
    })
  }, [team?.drivers])

  const managersOptions = managers.map((manager) => ({
    label: manager?.firstName
      ? `${capitalize(manager?.firstName)} ${capitalize(manager.lastName)}`
      : manager?.email,
    value: manager.id,
    key: manager.id,
  }))

  const driversOptions = drivers.map((driver) => ({
    label: capitalize(driver?.name),
    value: driver.id,
    key: driver.id,
  }))

  const zonesOptions = zones.map((zone) => ({
    label: zone?.name,
    value: zone.id,
    key: zone.id,
  }))

  const onFinish = async (values: CreateTeamPayload) => {
    try {
      const { name, zones, managers, drivers } = values

      const payload = {
        name: name,
        zones: zones,
        managers: managers,
        drivers: drivers,
      }

      if (team?.id) {
        await updateTeam({ ...payload, id: team.id })
      } else {
        await createTeam(payload)
      }
      message.success(t("success.createOrUpdateTeam"))
      if (cancelCallback) {
        cancelCallback()
      }
    } catch (error) {
      console.log(error)
      message.error(t("errors.createOrUpdateTeam"))
    }
  }
  const mainForm = (
    <>
      <Form.Item
        name="name"
        label={t("common.teamName")}
        rules={[{ required: true, message: t("profile.TeamSettings.inputTeamName") }]}
      >
        <Input prefix=" " name="name" placeholder={t("profile.TeamSettings.chooseTeamName")} />
      </Form.Item>
      <Form.Item name="managers" label={t("common.fleetManagers")}>
        <Select
          id="select-managers"
          className="cursor-pointer"
          options={managersOptions}
          allowClear
          popupMatchSelectWidth={false}
          mode="multiple"
          style={{ width: "100%" }}
          placeholder={t("common.form.selectManagers")}
          dropdownRender={(menu) => <>{menu}</>}
        />
      </Form.Item>
      <Form.Item name="drivers" label={t("common.form.drivers")}>
        <Select
          id="select-managers"
          className="cursor-pointer"
          options={driversOptions}
          allowClear
          popupMatchSelectWidth={false}
          mode="multiple"
          style={{ width: "100%" }}
          placeholder={t("common.form.selectDrivers")}
          filterOption={(inputValue, option) =>
            !!option?.label?.toLowerCase().includes(inputValue?.toLowerCase())
          }
          dropdownStyle={{ overflowY: "scroll" }}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Space style={{ padding: "0 8px 4px" }}>
                <DriverModal
                  defaultTeamId={team?.id}
                  button={
                    <div className="flex cursor-pointer">
                      <FontAwesomeIcon
                        className="flex flex-shrink-0 h-3 mx-auto my-auto text-kosmoBlue ml-1 mr-2"
                        icon={faCirclePlus}
                      />
                      <p> {t("common.addDriver")}</p>
                    </div>
                  }
                />
              </Space>
            </>
          )}
        />
      </Form.Item>
      {zonesOptions.length > 0 && (
        <Form.Item name="zones" label={t("common.form.zones")}>
          <Select
            id="select-zones"
            className="cursor-pointer"
            options={zonesOptions}
            allowClear
            popupMatchSelectWidth={false}
            optionFilterProp="label"
            size="large"
            mode="multiple"
            style={{ width: "100%" }}
            placeholder={t("common.form.selectZones")}
            dropdownRender={(menu) => <>{menu}</>}
          />
        </Form.Item>
      )}
    </>
  )
  return (
    <Form
      id="create-team-form"
      form={form}
      layout="vertical"
      className="gap-y-4"
      validateTrigger="onSubmit"
      onFinish={onFinish}
      requiredMark={false}
    >
      {/* NASH EDG DEMO START */}
      {!flags?.nash_edg_demo?.enabled ? (
        mainForm
      ) : (
        <Tabs>
          <TabPane tab={t("common.team")} key="team">
            {mainForm}
          </TabPane>
          <TabPane tab={t("common.settings")} key="settings">
            <div className="flex flex-col gap-y-3">
              <div className="flex gap-x-3 items-center">
                <Form.Item name="requireRecipientId" className="mb-0">
                  <Switch
                    checked={requireRecipientId}
                    onChange={() => {
                      setRequireRecipientId(!requireRecipientId)
                    }}
                  />
                </Form.Item>
                <LabelWithTooltip
                  label={t("Require Recipient ID")}
                  tooltipContent={t(
                    "A Driver can only complete his stop after verifying a recipient ID"
                  )}
                />
              </div>
              <div className="flex gap-x-3">
                <Form.Item name="nominatedItems" className="mb-0">
                  <Switch
                    checked={nominatedItems}
                    onChange={() => {
                      setNominatedItems(!nominatedItems)
                    }}
                  />
                </Form.Item>
                <LabelWithTooltip
                  label={t("Require named items")}
                  tooltipContent={t("Items can only be picked-up by the mentioned recipient")}
                />
              </div>
              <div className="flex gap-x-3">
                <Form.Item name="driverRequirements" className="mb-0">
                  <Switch
                    checked={driverRequirementsEnabled}
                    onChange={() => {
                      setDriverRequirementsEnabled(!driverRequirementsEnabled)
                    }}
                  />
                </Form.Item>
                <LabelWithTooltip
                  label={t("Driver start checklist required")}
                  tooltipContent={t("profile.DriverAppSettings.proofOfPickupRequiredTooltip")}
                />
              </div>
              <div className={`flex ${!driverRequirementsEnabled && "hidden"}`}>
                <div className="w-[60px]" />
                <div className="flex flex-col">
                  <Form.Item className="mb-0">
                    <Checkbox className="!ml-0" defaultChecked={true}>
                      {t("common.identityVerification")}
                    </Checkbox>
                  </Form.Item>
                  <Form.Item className="mb-0">
                    <Checkbox className="!ml-0" defaultChecked={true}>
                      {t("common.vehiclePhysicalCondition")}
                    </Checkbox>
                  </Form.Item>
                  <Form.Item className="mb-0">
                    <Checkbox className="!ml-0" defaultChecked={true}>
                      {t("common.engine")}
                    </Checkbox>
                  </Form.Item>
                  <Form.Item className="mb-0">
                    <Checkbox className="!ml-0" defaultChecked={true}>
                      {t("common.mirrorsAndWindows")}
                    </Checkbox>
                  </Form.Item>
                  <Form.Item className="mb-0">
                    <Checkbox className="!ml-0" defaultChecked={true}>
                      {t("common.gasSystem")}
                    </Checkbox>
                  </Form.Item>
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>
      )}
      {/* NASH EDG DEMO END */}
      <div className="flex w-full justify-end space-x-4">
        {cancelCallback && (
          <KosmoButton type="default" onClick={cancelCallback}>
            {t("common.cancel")}
          </KosmoButton>
        )}
        <KosmoButton type="primary" htmlType="submit" isDisabled={isLoading}>
          {team?.id ? t("common.save") : t("common.create")}
        </KosmoButton>
      </div>
    </Form>
  )
}

export default CreateTeamForm
