import { createRoutesAtom } from "@/atoms/createRoutesAtom"
import { isPayingUserSelector } from "@/atoms/userAtom"
import { PROFILE_PAGE_PATH } from "@/constants/routes"
import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import { SandboxContext } from "@/layout/Nav/SandboxSwitcher"
import KosmoButton from "@/UI/Buttons/KosmoButton"
import PreventWithModal from "@/UI/PreventWithModal"
import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFlags } from "flagsmith/react"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilState, useRecoilValue } from "recoil"

interface OptimizationModalButtonProps {
  skipUpload?: boolean
  type: "default" | "primary"
  isDisabled?: boolean
  overrideButton?: React.ReactElement
  optimizationCallback: () => Promise<void>
}

const OptimizationModalButton: React.FC<OptimizationModalButtonProps> = ({
  type = "primary",
  skipUpload = false,
  overrideButton,
  isDisabled,
  optimizationCallback,
}) => {
  const { t } = useTranslation()
  const { sandboxRouter } = useSandboxRouter()
  const isPayingUser = useRecoilValue(isPayingUserSelector)
  const [createRoutes, setCreateRoutes] = useRecoilState(createRoutesAtom)
  const isUploadMode = !skipUpload && createRoutes?.metadata?.total === 0

  const setOpen = (_: boolean) => {
    setCreateRoutes((createRoutesState) => ({
      ...createRoutesState,
      isOpen: true,
      skipUpload,
      optimizationCallback,
    }))
  }
  const sandbox = useContext(SandboxContext)
  const flags = useFlags(["hide_optimize_draft_orders"])
  const buttonProps = {
    size: "middle",
    type: type,
    isDisabled: !!createRoutes?.isLoadingOptimize || isDisabled,
    isLoading: createRoutes?.isLoadingOptimize,
  }
  const createRouteButton = (
    <KosmoButton>
      <FontAwesomeIcon className="mr-2" icon={faWandMagicSparkles} />
      {!!isUploadMode
        ? t("new-orders.RouteOptimizationModal.createRoutes")
        : t("new-orders.RouteOptimizationModal.optimize")}
    </KosmoButton>
  )

  if (flags.hide_optimize_draft_orders.enabled || sandbox.enabled) {
    return <></>
  }

  if (!isPayingUser) {
    return (
      <PreventWithModal
        enable={!isPayingUser}
        label={t("onboarding.freePlanBannerDescription")}
        cta={{
          label: t("profile.planCard.startNow"),
          onClick: () => sandboxRouter.push(`${PROFILE_PAGE_PATH}/?p=plans`),
        }}
      >
        {React.cloneElement(overrideButton || createRouteButton, {})}
      </PreventWithModal>
    )
  }

  return (
    <>
      {React.cloneElement(overrideButton || createRouteButton, {
        ...buttonProps,
        onClick: setOpen,
      })}
    </>
  )
}

export default OptimizationModalButton
