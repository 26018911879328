export const LocationPin = ({ color }: { color?: string }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="6" fill={color} />
      <path
        d="M21.5 12.332C21.5 18.1654 14 23.1654 14 23.1654C14 23.1654 6.5 18.1654 6.5 12.332C6.5 10.3429 7.29018 8.43525 8.6967 7.02873C10.1032 5.62221 12.0109 4.83203 14 4.83203C15.9891 4.83203 17.8968 5.62221 19.3033 7.02873C20.7098 8.43525 21.5 10.3429 21.5 12.332Z"
        fill="white"
      />
      <path
        d="M14 14.832C15.3807 14.832 16.5 13.7127 16.5 12.332C16.5 10.9513 15.3807 9.83203 14 9.83203C12.6193 9.83203 11.5 10.9513 11.5 12.332C11.5 13.7127 12.6193 14.832 14 14.832Z"
        fill={color}
      />
    </svg>
  )
}
