import { useState } from "react"
import { patchOrgPreferences, postOrg } from "@/api/orgs"
import { userAtom } from "@/atoms/userAtom"
import { message } from "antd"
import { useRecoilState, useSetRecoilState } from "recoil"
import { useUser } from "./useUser"
import { setActiveOrg } from "@/utils/localStorage"
import {
  IPreference,
  OrgBusinessFormValues,
  OrgMarketingFormValues,
} from "@/types/organizations.types"
import { SuccessNotification } from "@/UI/Notifications/NotificationTemplate.component"
import { useTranslation } from "react-i18next"
import { NEW_ORDERS_PAGE_PATH } from "@/constants/routes"
import axios from "@/modules/shared/AxiosInterceptor"
import { ErrorKeys } from "@/types/errors.types"
import { defaultMetadata, ordersAtom } from "@/atoms/ordersAtom"
import { stopsAtom } from "@/atoms/stopsAtom"
import { defaultGlobalMetadata, draftOrdersAtom } from "@/atoms/draftOrdersAtom"
import { useSandboxRouter } from "./useSandboxRouter"
import { CoordinatesLatLng } from "@/types/createSingleOrder.types"
import { useFlagsmith } from "flagsmith/react"
import { IState } from "flagsmith/types"
import { isEmpty } from "lodash"
import { defaultOptimizationConfig, OptimizationConfig } from "@/types/optimization"
import { putOptimizationConfig } from "@/api/optimization"
import { DistanceUnit } from "@/types/localization.types"

type CreateOrgParams = OrgBusinessFormValues & { distanceUnit: DistanceUnit } & {
  coordinates: CoordinatesLatLng
} & {
  optimizationConfig: Pick<
    OptimizationConfig,
    "shouldEndAtPickupLocation" | "maxBatchSize" | "howLongToWaitAtLocation"
  >
} & Partial<OrgMarketingFormValues>

interface IUseOrg {
  updateOrgPreferences: (preferences: IPreference) => Promise<void>
  createNewOrg: (orgData: CreateOrgParams, newUser?: boolean) => Promise<void>
  switchOrg: (orgId: string) => Promise<void>
  isLoading: boolean
}

export interface FakeRecipientCoords {
  lat: number
  lng: number
  address: string
}

export const useOrg = (): IUseOrg => {
  const { t } = useTranslation()
  const { sandboxRouter } = useSandboxRouter()
  const flagsmith = useFlagsmith()

  const [user, setUser] = useRecoilState(userAtom)
  const setOrders = useSetRecoilState(ordersAtom)
  const setStops = useSetRecoilState(stopsAtom)
  const setDraftOrders = useSetRecoilState(draftOrdersAtom)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { fetchUserProfile } = useUser()

  const createNewOrg = async (
    {
      orgName,
      email,
      phone,
      country,
      address,
      expectedOrders,
      expectedEcommerce,
      businessType,
      other,
      department,
      utm_campaign,
      // city,
      utm_medium,
      utm_content,
      utm_source,
      utm_term,
      cta_source,
      coordinates,
      optimizationConfig,
      distanceUnit,
    }: CreateOrgParams,
    newUser?: boolean
  ) => {
    setIsLoading(true)
    try {
      delete axios.defaults.headers.common["x-org-id"]
      const { data: org } = await postOrg({
        name: orgName,
        coordinates: { latitude: coordinates.lat, longitude: coordinates.lng },
        email,
        phone,
        country,
        address,
        marketingData: {
          expectedOrders,
          expectedEcommerce,
          businessType,
          other,
          department,
          utm_campaign,
          utm_medium,
          utm_content,
          utm_source,
          utm_term,
          cta_source,
        },
        distanceUnit,
      })

      setActiveOrg(org.id)

      axios.defaults.headers.common["x-org-id"] = org.id

      await putOptimizationConfig({
        ...defaultOptimizationConfig,
        maxBatchSize: Number(optimizationConfig.maxBatchSize),
        shouldEndAtPickupLocation: !!optimizationConfig.shouldEndAtPickupLocation,
        howLongToWaitAtLocation: Number(optimizationConfig.howLongToWaitAtLocation),
      })

      await fetchUserProfile(undefined, true)
      sandboxRouter.replace(`${sandboxRouter?.pathname}/?t=new`, undefined, { shallow: true })
      SuccessNotification(
        t("common.notification.organizationCreated"),
        t("common.notification.organizationCreatedSuccess"),
        "topRight"
      )

      if (newUser) {
        sandboxRouter.push(`${NEW_ORDERS_PAGE_PATH}/?t=new`)
      }
    } catch (error: any) {
      if (error?.response?.data?.key === ErrorKeys.DUPLICATE_ORG) {
        message.error(t("common.form.errorDuplicateOrg"))
        throw error
      }
      message.error(t("common.form.errorCreatingForm", { entity: "your organization" }))
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const refreshFlags = async (orgId: string) => {
    try {
      await flagsmith.identify(user.id, { org_id: orgId })
    } catch {
      const flagsmithState = JSON.parse(localStorage?.getItem("BULLET_TRAIN_DB") || "{}")
      if (!!flagsmithState && flagsmithState?.api && !isEmpty(flagsmithState?.flags)) {
        flagsmith.setState(flagsmithState as IState)
      }
    }
  }

  const switchOrg = async (orgId: string) => {
    setActiveOrg(orgId)
    axios.defaults.headers.common["x-org-id"] = orgId
    setIsLoading(true)
    try {
      await fetchUserProfile()
      setOrders({ orders: [], metadata: defaultMetadata })
      setStops({ stops: [], metadata: defaultMetadata })
      setDraftOrders((draftOrdersState) => ({
        ...draftOrdersState,
        orders: [],
        metadata: defaultGlobalMetadata,
      }))
      await refreshFlags(orgId)
    } catch (error) {
      console.log(error + " switching org")
    } finally {
      setIsLoading(false)
    }
  }

  const updateOrgPreferences = async (preference: IPreference) => {
    setIsLoading(true)
    try {
      await patchOrgPreferences(preference)

      setUser((state) => ({
        ...state,
        preference: {
          ...state.preference,
          ...preference,
          branding: {
            ...state.preference.branding,
            ...preference.branding,
            companyLogoUrl: state.preference.branding?.companyLogoUrl,
          },
        },
      }))

      message.success("Successfully updated your preferences")
    } catch (error) {
      message.error("An error occurred while updating your preferences")
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  return { updateOrgPreferences, switchOrg, isLoading, createNewOrg }
}
