import { Typography } from "antd"

const { Title } = Typography

interface IFormStepperTitleProps {
  title?: React.ReactNode
  subtitle?: string | React.ReactElement
  icon?: JSX.Element
  centered?: boolean
}

const FormStepperTitle: React.FC<IFormStepperTitleProps> = ({
  title,
  subtitle,
  icon,
  centered,
}) => {
  return (
    <div className="flex w-full pt-1 select-none mb-2 xs:mb-4">
      <div
        className={`${centered ? "justify-center" : "justify-between"} flex items-center w-full`}
      >
        <div className="flex flex-row items-center">
          {!!icon && <div className="h-7 w-7 mr-3 flex-shrink-0">{icon}</div>}
          {title && typeof title === "string" && (
            <Title
              level={3}
              className="title-0-mb break-words"
              style={{ marginBottom: "0 !important" }}
            >
              {title}
            </Title>
          )}
          {title && typeof title !== "string" && title}
        </div>
        {subtitle && <div className="text-gray-600 text-md m-0">{subtitle}</div>}
      </div>
    </div>
  )
}

export default FormStepperTitle
